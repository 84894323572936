<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space position-relative reatesPage">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="flex-between">
                            <h1 class="mb-2 f-22 bold letter-space-0">{{$t('riskDisc.heading')}}</h1>
                        </div>
                    </div>
                    <div class="col-12" v-if="Object.keys(store.flavordata).length">
                        <div class="static" v-if="store.flavordata?.isEu">
                            <!-- <p>© {{$t('footer.euCR')}}</p> -->
                            <!-- <p>{{$t('TOSEU.eText272')}}</p>
                            <p>{{$t('TOSEU.eText273')}}</p>
                            <ul class="dotList ms-3">
                                <li>{{$t('TOSEU.eText274')}}</li>
                                <li>{{$t('TOSEU.eText275')}}</li>
                                <li>{{$t('TOSEU.eText276')}}</li>
                                <li>{{$t('TOSEU.eText277')}}</li>
                            </ul>
                            <p>{{$t('TOSEU.eText278')}}</p>
                            <p>{{$t('TOSEU.eText279')}}</p>

                            <div class="flex-between">
                                <h1 class="mb-2 f-22 regular">{{$t('riskDisc.fullDisc')}}</h1>
                            </div> -->
                            <p>{{$t('TOS.gText144')}}</p>
                            <p>{{$t('TOS.gText145')}}(<a href="https://zulutrade.com" class="secondary" >https://zulutrade.com</a>) {{$t('TOS.gText145a')}}</p>
                            <!-- <p>{{$t('TOS.gText146')}}</p> -->
                            <p>{{$t('TOS.gText147')}}</p>
                            <p>{{$t('TOS.gText148')}}</p>
                            <div class="flex-between">
                                <h1 class="mb-2 f-22 bold letter-space-0">{{$t('riskDisc.fullDisc')}}</h1>
                            </div>
                            <p>{{$t('riskDisc.gtext5')}}</p>
                            <p>{{$t('riskDisc.gtext6')}}</p>
                            <p>{{$t('riskDisc.gtext7')}}</p>
                            <p>{{$t('riskDisc.gtext8')}}</p>
                            <p>{{$t('riskDisc.gtext9')}}</p>
                        </div>
                        <div class="static" v-else>
                            <!-- <p>© {{$t('footer.globalCR')}}</p> -->
                            <p>{{$t('TOS.gText144')}}</p>
                            <p>{{$t('TOS.gText145')}}(<a href="https://zulutrade.com" class="secondary" >https://zulutrade.com</a>) {{$t('TOS.gText145a')}}</p>
                            <!-- <p>{{$t('TOS.gText146')}}</p> -->
                            <p>{{$t('TOS.gText147')}}</p>
                            <p>{{$t('TOS.gText148')}}</p>
                            <div class="flex-between">
                                <h1 class="mb-2 f-22 bold letter-space-0">{{$t('riskDisc.fullDisc')}}</h1>
                            </div>
                            <p>{{$t('riskDisc.gtext5')}}</p>
                            <p>{{$t('riskDisc.gtext6')}}</p>
                            <p>{{$t('riskDisc.gtext7')}}</p>
                            <p>{{$t('riskDisc.gtext8')}}</p>
                            <p>{{$t('riskDisc.gtext9')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
  data() {
    return {
    };
  },
};
</script>